@import-normalize;

$fontFamily: 'FiraSans';

body {
  margin: 0;
  font-family: $fontFamily, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
  font-size: unset;
}

p {
  margin: 0;
}

b {
  font-weight: normal;
}

i {
  font-style: normal;
}

a {
  outline: none;
  color: unset;
  text-decoration: unset;
}

button {
  border: none;
  outline: none;
  padding: 0;
  line-height: unset;
  font-size: unset;
  font-family: unset;
  color: unset;
  background-color: unset;
  cursor: pointer;
}

ul, ol {
  margin: 0;
}

table {
  border-spacing: 0;
}

td {
  padding: 0;
}

code {
  font-family: $fontFamily, sans-serif;
}

strong {
  font-weight: normal;
}

em {
  font-style: normal;
}

mark {
  color: unset;
  background-color: transparent;
}
